<template>
	<svg width="1289" height="556" viewBox="0 0 1289 556" fill="none" xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink">
		<g fill="#e3cd9f" id="practitioner website">
			<parent-paths></parent-paths>
			<creds-insurance></creds-insurance>
			<bee-hive-game></bee-hive-game>
			<events></events>
			<certification></certification>
			<community-resources></community-resources>
			<contact-practitioner></contact-practitioner>
			<dsm-asam></dsm-asam>
			<ehr></ehr>
			<emergency></emergency>
			<forms></forms>
			<grants></grants>
			<cpt-codes></cpt-codes>
			<messenger></messenger>
			<my-contacts></my-contacts>
			<online-store></online-store>
			<supervision></supervision>
			<social-media></social-media>
			<self-care></self-care>
			<bhfl></bhfl>
			<wellness-centers></wellness-centers>
			<treatment-planning></treatment-planning>
			<services></services>
			<billing></billing>
			<bhu></bhu>
			<practive-manager></practive-manager>
			<patient-portal></patient-portal>
			<mid-paths></mid-paths>
			<calendar></calendar>
			<bottom-paths></bottom-paths>
		</g>
		<definitions></definitions>
	</svg>
</template>

<script>
import CredsInsurance from './ProMenuLargeItems/CredsInsurance.vue';
import BeeHiveGame from './ProMenuLargeItems/BeeHiveGame.vue';
import Events from './ProMenuLargeItems/Events.vue';
import Certification from './ProMenuLargeItems/Certification.vue';
import CommunityResources from './ProMenuLargeItems/CommunityResources.vue';
import ContactPractitioner from './ProMenuLargeItems/ContactPractitioner.vue';
import DsmAsam from './ProMenuLargeItems/DsmAsam.vue';
import Ehr from './ProMenuLargeItems/Ehr.vue';
import Emergency from './ProMenuLargeItems/Emergency.vue';
import Grants from './ProMenuLargeItems/Grants.vue';
import TreatmentPlanning from './ProMenuLargeItems/TreatmentPlanning.vue';
import CptCodes from './ProMenuLargeItems/CptCodes.vue';
import Forms from './ProMenuLargeItems/Forms.vue';
import SocialMedia from './ProMenuLargeItems/SocialMedia.vue';
import OnlineStore from './ProMenuLargeItems/OnlineStore.vue';
import Supervision from './ProMenuLargeItems/Supervision.vue';
import WellnessCenters from './ProMenuLargeItems/WellnessCenters.vue';
import Services from './ProMenuLargeItems/Services.vue';
import Bhu from './ProMenuLargeItems/Bhu.vue';
import PractiveManager from './ProMenuLargeItems/PractiveManager.vue';
import Billing from './ProMenuLargeItems/Billing.vue';
import ParentPaths from './ProMenuLargeItems/ParentPaths.vue';
import BottomPaths from './ProMenuLargeItems/BottomPaths.vue';
import MidPaths from './ProMenuLargeItems/MidPaths.vue';
import Definitions from './ProMenuLargeItems/Definitions.vue';
import Messenger from './ProMenuLargeItems/Messenger.vue';
import MyContacts from './ProMenuLargeItems/MyContacts.vue';
import SelfCare from './ProMenuLargeItems/SelfCare.vue';
import Bhfl from './ProMenuLargeItems/Bhfl.vue';
import Calendar from './ProMenuLargeItems/Calendar.vue';
import PatientPortal from './ProMenuLargeItems/PatientPortal.vue';

export default {
	components: {
		CredsInsurance,
		BeeHiveGame,
		Events,
		Certification,
		CommunityResources,
		ContactPractitioner,
		DsmAsam,
		Ehr,
		Emergency,
		Grants,
		TreatmentPlanning,
		CptCodes,
		Forms,
		SocialMedia,
		OnlineStore,
		Supervision,
		WellnessCenters,
		Services,
		Bhu,
		PractiveManager,
		Billing,
		ParentPaths,
		BottomPaths,
		MidPaths,
		Definitions,
		Messenger,
		MyContacts,
		SelfCare,
		Bhfl,
		Calendar,
		PatientPortal,
	},
	setup() {
		return {};
	},
};
</script>